@tailwind base;
@tailwind components;
@tailwind utilities;




@layer base {

  h1 {
    font-size: 36px;
    line-height: 40px;
}

h2 {
    font-size: 30px;
    line-height: 40px;
}

h3 {
    font-size: 24px;
    line-height: 40px;
}

h4 {
    font-size: 18px;
    line-height: 20px;
}
}

 body {
  
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 }